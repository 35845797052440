@import '~styles/variables/colours.scss';

.map-link {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: $dark-green;
  border: 1px solid $yellow-green;
  box-sizing: border-box;
  padding: 8px;
  cursor: pointer;

  svg {
    width: 32px;
    height: 32px;
    
    path {
      stroke: white;
    }
  }
}