@use '~styles/mixins/media-queries.scss';
@use '~styles/variables/colours.scss';

$selected-colour: colours.$white;
$selected-background: colours.$dark-green;
$border-colour: colours.$yellow-green;

$unselected-colour: colours.$light-grey;
$unselected-background: colours.$extra-dark-green;

.info-nav {
  position: relative;
  height: 64px;
  box-sizing: border-box;
  padding-top: 16px;
  background-color: $unselected-background;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
    width: 0;
    background-color: $border-colour;
    opacity: 0;
    transition: width 0.2s linear;
  }

  @include media-queries.not-mobile {
    &::after {
      top: unset;
      bottom: 0;
    }
  }

  .icon {
    margin: 0 auto;
    width: 32px;
    height: 32px;

    svg {
      width: 32px;
      height: 32px;
      stroke: $unselected-colour;

      g, path {
        stroke: $unselected-colour;
      }
    }
  }

  &.selected {
    background-color: $selected-background;
    &::after {
      opacity: 1;
      width: 100%;
    }

    .icon {
      svg {
        g, path {
          stroke: $selected-colour;
        }
      }
    }
  }
}

@include media-queries.not-mobile {
  .info-nav {
    background-color: colours.$white;
    &.selected {
      background-color: colours.$white;
      .icon {
        svg {
          g, path {
            stroke: colours.$black;
          }
        }
      }
    }
  }
}
