@import '~styles/variables/colours.scss';

.loading-modal {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($black, 0.75);

  &--icon {
    position: absolute;
    inset: calc(50% - 64px);
    animation: fade 1.5s linear infinite;
  }
}

@keyframes fade {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
