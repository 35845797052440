@use '../variables/breakpoints' as *;

@mixin small-mobile {
  @media
    screen and (max-width: $xs--max-width)
  {
    @content;
  }
}

@mixin mobile {
  @media
    screen and (min-width: $sm--min-width) and (max-width: $sm--max-width)
  {
    @content;
  }
}

@mixin all-mobile {
  @media
    screen and (max-width: $sm--max-width)
  {
    @content;   
  }
}

@mixin not-mobile {
  @media 
    screen and (min-width: $sm--max-width)
  {
    @content;  
  }
}

@mixin tablet {
  @media
    screen and (min-width: $md--min-width) and (max-width: $md--max-width)
  {
    @content;
  }
}

@mixin small-desktop {
  @media screen and (min-width: $lg--min-width) and (max-width: $lg--max-width) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $xl--min-width) {
    @content;
  }
}
