@use '~styles/mixins/media-queries.scss';
@import '~styles/variables/colours.scss';

body {
  margin: unset;
}

.landing-page {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;

  .landing-content {
    --fixed-size: 80px;
    --edge-calc-columns: calc((100% - 3 * var(--fixed-size)) / 2);
    --edge-calc-rows: calc((100% - 8 * var(--fixed-size)) / 2);

    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns:
      var(--edge-calc-columns)
      repeat(3, var(--fixed-size))
      var(--edge-calc-columns);
    grid-template-rows:
      var(--edge-calc-rows)
      repeat(8, var(--fixed-size))
      var(--edge-calc-rows);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  
    .image {
      grid-column: 1 / 6;
      grid-row: 1 / 11;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .overlay {
      grid-column: 1 / 6;
      grid-row: 1 / 11;
      background-color: rgba($black, 0.25);
    }

    .header {
      grid-column: 2 / 5;
      grid-row: 2 / 3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      color: $white;
      font-size: 32px;
      opacity: 0;
      transform: translateY(8px);
    }

    .icon {
      grid-column: 2 / 5;
      grid-row: 3 / 6;
      opacity: 0;
      transform: translateY(8px);
    }

    .sub-header {
      grid-column: 2 / 5;
      grid-row: 7 / 8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      color: $white;
      font-size: 40px;
      opacity: 0;
      transform: translateY(8px);
    }

    .enter-button {
      grid-column: 2 / 5;
      grid-row: 9 / 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      background-color: $yellow-green;
      color: $white;
      font-size: 32px;
      cursor: pointer;
      opacity: 0;
      transform: translateY(8px);
    }

    .fade-in {
      animation: fadeIn 500ms ease-in-out forwards;
    }

    .fade-in-250 {
      animation: fadeIn 500ms ease-in-out 250ms forwards;
    }

    .fade-in-450 {
      animation: fadeIn 500ms ease-in-out 450ms forwards;
    }

    .fade-in-650 {
      animation: fadeIn 500ms ease-in-out 650ms forwards;
    }
  }

  @include media-queries.small-mobile {
    .landing-content {
      --fixed-size:48px;

      .sub-header {
        font-size: 32px;
      }

      .enter-button {
        font-size: 24px;
      }
    }
  }
}

.fade-out {
  animation: fadeOut 250ms ease-in-out forwards;
}

@keyframes fadeOut {
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
