@use '~styles/variables/colours.scss';

.list-item {
  display: flex;
  width: 100%;
  height: 32px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 32px;
  font-size: 16px;
  color: colours.$dark-grey;

  .icon {
    flex: 1;
    padding-top: 2px;

    svg {
      width: 32px;
      height: 32px;
      path {
        stroke: colours.$dark-grey;
      }
      g {
        fill: colours.$dark-grey;
      }
    }
  }

  .content {
    flex: 4;
    border-bottom: 1px solid colours.$dark-grey;
  }

  .due-date {
    flex: 2;
    border-bottom: 1px solid colours.$dark-grey;
    color: colours.$light-green;
    text-align: right;

    &.overdue {
      color: colours.$light-red;
    }
  }
}
