@use '~styles/mixins/media-queries.scss';

.location-filters {
  opacity: 0;
  transform: translateY(8px);
  animation: fadeIn 500ms ease-in-out 250ms forwards;
}

.location-card-list {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
  padding: 0 16px;
  opacity: 0;
  transform: translateY(8px);
  animation: fadeIn 500ms ease-in-out 450ms forwards;
}

@include media-queries.not-mobile {
  .location-filters {
    animation: fadeIn 500ms ease-in-out 450ms forwards;
  }

  .location-card-list {
    padding: 0 32px;
    justify-content: unset;
    gap: 32px;
    animation: fadeIn 500ms ease-in-out 650ms forwards;
  }
}

@keyframes fadeIn {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
