@use '~styles/variables/colours.scss';

.carousel-container {
  position: relative;
  width: 100%;
  max-width: 512px;

  .carousel {
    display: flex;
    overflow: hidden;
    height: 256px;
    width: 100%;
    transform: translateX(0);

    .carousel-item {
      min-width: 100%;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.nav-right,
.nav-left {
  position: absolute;
  top: calc(50% - 24px);
  width: 48px;
  height: 48px;
  cursor: pointer;
  background-color: colours.$black;
  opacity: 0.75;

  .arrow {
    position: absolute;
    width: 0;
    height: 0;
    top: 8px;
    left: 20px;
 
    &.left {
      left: 12px;
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
      border-right: 16px solid colours.$white;
    }

    &.right {
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
      border-left: 16px solid colours.$white;
    }
  }
}

.nav-left {
  left: 0;
}

.nav-right {
  right: 0;
}
