@use '~styles/mixins/media-queries.scss';

.info-page {
  padding: 16px;

  .dialog {
    opacity: 0;
    transform: translateY(8px);
    animation: fadeIn 500ms ease-in-out 250ms forwards;
  }

  .jobs {
    padding-top: 48px;
    opacity: 0;
    transform: translateY(8px);
    animation: fadeIn 500ms ease-in-out 450ms forwards;
  }
  
  @include media-queries.not-mobile {
    .dialog {
      animation: fadeIn 500ms ease-in-out 450ms forwards;
    }

    .jobs {
      animation: fadeIn 500ms ease-in-out 650ms forwards;
    }
  }
}

@keyframes fadeIn {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
