@use '~styles/mixins/media-queries.scss';
@import '~styles/variables/colours.scss';

.location-filter-container {
  height: 64px;

  &.expand {
    height: 100vh;
  }

  @include media-queries.desktop {
    &.expand {
      height: max-content;
      padding-bottom: 32px;
    }
  }
}

.location-filter {
  $foreground: $dark-green;
  $background: $white;

  display: flex;
  box-sizing: border-box;
  user-select: none;

  height: 64px; 
  width: fit-content;
  background-color: $background;
  cursor: pointer;

  &--icon {
    width: 32px;
    padding: 16px;

    svg {
      width: 32px;
      height: 32px;
      path {
        stroke: $foreground;
      }
    }
  }

  &--text {
    width: fit-content;
    padding-top: 16px;
    padding-right: 16px;
    color: $foreground;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    line-height: 32px;
  }

  @include media-queries.not-mobile {
    &--icon {
      padding: 16px 32px;
    }
  }
}

.open {
  background-color: $dark-green;
  color: $yellow-green;

  svg {
    path {
      stroke: $yellow-green;
      fill: $yellow-green;
    }
  }
}

.categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 48px;
  font-size: 22px;

  .category {
    box-sizing: border-box;
    width: 50%;
    
    input[type="checkbox"] {
      box-sizing: border-box;
      position: relative;
      width: 16px;
      height: 16px;
      border: 2px solid $yellow-green;
      appearance: none;
      cursor: pointer;
      transform: translateY(3px);
    }

    input[type="checkbox"]::before {
      content: '';
      position: absolute;
      inset: 2px;
      width: 8px;
      height: 8px;
      background-color: $yellow-green;
      border: none;
      transform: scale(0);
      transition: transform 0.25s ease-in-out;
    }

    input[type="checkbox"]:checked::before {
      transform: scale((1));
    }

    label {
      padding-left: 8px;
    }
  }

  @include media-queries.not-mobile {
    .category {
      width: 25%;
    }
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 16px;
  gap: 16px;
}

.apply-button {
  appearance: none;
  border: none;
  width: 100%;
  height: 64px;
  background-color: $yellow-green;
  color: $dark-green;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  cursor: pointer;

  &.reset {
    box-sizing: border-box;
    border: 2px solid $yellow-green;
    background-color: $dark-green;
    color: $yellow-green;
  }
}

@include media-queries.not-mobile {
  .buttons {
    margin-top: 16px;
  }
  .apply-button {
    width: 25%;
  }
}