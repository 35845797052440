@use '~styles/mixins/media-queries.scss';
@import '~styles/variables/colours.scss';

$borderRadius: 8px;

.location-card-container {
  box-sizing: border-box;
  user-select: none;
  display: grid;
  width: 100%;
  max-width: 512px;
  height: 256px;
  grid-template-columns: repeat(3, 33.333%);
  grid-template-rows: repeat(4, 25%);
  border-radius: $borderRadius;
  box-shadow: 0 32px 24px -16px $light-grey;
  position: relative;
  cursor: pointer;

  &--picture {
    grid-column: 1 / 4;
    grid-row: 1 / 5;
    overflow: none;
    
    img {
      border-radius: $borderRadius;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--map-link {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &--web-link {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &--details {
    grid-column: 1 / 3;
    grid-row: 4 / 5;
    z-index: 1;

    background-color: $dark-green;
    border-top: 1px solid $yellow-green;
    border-right: 1px solid $yellow-green;
    border-top-right-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;

    font-family: Verdana, Geneva, Tahoma, sans-serif;

    &--header {
      height: 50%;
      padding-left: 16px;

      font-weight: bolder;
      font-size: 18px;
      line-height: 32px;
      color: $white;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    
      &:hover {
        overflow: unset;
        text-overflow: unset;
      }
    }

    &--sub {
      height: 50%;
      padding-left: 16px;

      line-height: 16px;
      font-size: 14px;
      color: $yellow-green
    }
  }

  &--description {
    grid-column: 1 / 4;
    grid-row: 1 / 5;
    background-color: rgba($black, 0.75);
    border-radius: $borderRadius;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 16px;
    z-index: 1;
    
    &--header {
      font-weight: bolder;
      font-size: 18px;
      line-height: 32px;
      color: $light-blue;
    }

    &--content {
      padding-top: 16px;
      color: $white;
    }
  }
}

@include media-queries.not-mobile {
  .location-card-container {
    width: 320px;
    max-width: unset;
  }
}
