/** grey scale **/
$white: rgb(255, 255, 255);
$light-grey: rgb(173, 180, 201);
$dark-grey: rgb(131, 143, 162);
$black: rgb(21, 22, 30);

/** blues **/
$light-blue: rgb(172, 199, 227);
$dark-blue:rgb(112, 146, 195);

/** greens **/
$light-green: rgb(137, 135, 65);
$yellow-green: rgb(209, 150, 97);
$dark-green: rgb(59, 68, 56);
$extra-dark-green: rgb(49, 58, 46);

/** reds **/
$light-red: rgb(190, 53, 53);
