@use '~styles/variables/colours.scss';

.jobs-list {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .title {
    position: relative;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    color: colours.$dark-grey;
  }

  .title::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 33.33%;
    height: 2px;
    background-color: colours.$dark-grey;
  }
}
