@use '~styles/mixins/media-queries.scss';

.home-page {
  padding-bottom: 96px;
}

.nav-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  opacity: 0;
  transform: translateY(8px);
  animation: fadeIn 500ms ease-in-out 650ms forwards;
}

@include media-queries.not-mobile {
  .home-page {
    padding-top: 64px;
  }

  .nav-bar {
    position: fixed;
    top: 0;
    width: 100%;
    animation: fadeIn 500ms ease-in-out 250ms forwards;
  }
}

@keyframes fadeIn {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}