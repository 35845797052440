@use '~styles/mixins/media-queries.scss';
@use '~styles/variables/colours.scss';

.bottom-nav {
  display: flex;
  position: relative;
  height: 64px;
  width: 100%;

  .nav-element {
    flex: 1;
  }

  @include media-queries.not-mobile {
    .nav-element {
      flex: unset;
      width: 128px;
    }
  }
}

@include media-queries.not-mobile {
  .bottom-nav {
    background-color: white;
  }

  .bottom-nav::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: colours.$dark-green;
  }
}
