@use '~styles/variables/colours.scss';

.dialog-container {
  position: relative;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  user-select: none;
  border-radius: 8px;
  box-shadow: 0 32px 24px -16px colours.$light-grey;
  background-color: colours.$dark-green;

  .icon {
    position: absolute;
    top: 8px;
    right: 8px;
    svg {
      width: 32px;
      height: 32px;
      g {
        stroke: colours.$white;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: colours.$white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;

    .title {
      font-size: 14px;
      font-weight: bolder;
    }

    .text {
      font-size: 12px;
      color: colours.$light-grey;
    }
  }
}
