@import '~styles/variables/colours.scss';

@mixin hill {
  --path: polygon(
    0 0, 
    
    30% 0, 
    32.5% 0.3%,
    35% 0.6%,
    37.5% 1.2%,
    40% 2.4%,
    42.5% 4.8%,

    65% 40%, 
    66% 40.6%, 
    68% 41%,
    
    100% 41%, 
    100% 100%, 
    0 100%, 
    0 0
  );

  position: absolute;
  height: 60%;
  width: 100%;
  bottom: 0;

  background: linear-gradient(45deg, $dark-green, $light-green);
  clip-path: var(--path);
}

@mixin bertie-icon {
  --size: 64px;
  --border-width: 4px;

  position: relative;
  box-sizing: border-box;
  overflow: hidden;

  width: var(--size);
  height: var(--size);
  border: var(--border-width) solid $dark-grey;
  border-radius: 50%;
  background: radial-gradient(
    ellipse at 90% 20%,
    $yellow-green 5%,
    $light-blue 15%,
    $dark-blue 100%
  );

  &--hill {
    @include hill();
  }
}


.bertie-icon {
  &--small {
    @include bertie-icon();
  }

  &--medium {
    @include bertie-icon();
    --size: 128px;
    --border-width: 6px;
  }

  &--large {
    @include bertie-icon();
    --size: 256px;
    --border-width: 8px;
  }

  &--fill {
    @include bertie-icon();
    --size: 100%;
    --border-width: 4px;
  }
}
